@import '/globals';
@import '../node_modules/sanitize.css/sanitize.css';
@import 'Font.scss';

:root {
    --brand-blue: #2b3342;
    --brand-gray: #f7f7f7;
    --brand-gray-light: #f1f1f1;
    --brand-orange: #ff7a00;
    --brand-black: #000;
    --brand-white: #fff;
    --brand-red: #c10000;
    --border-thick: 10px;
    --border-thin: 2px;
}

body {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-style: normal;
}

// Sticky footer
html,
body {
    width: 100%;
    height: 100%;
}

main {
    flex-grow: 1;
    padding: 20px;
    min-height: 100vh;

    h1 {
        text-align: center;
        margin-bottom: 40px;

        @include min600 {
            margin-bottom: 120px;
        }

        &::after {
            background: var(--brand-black);
            height: 2px;
            width: 100px;
            content: '';
            display: block;
            margin: 0 auto;
        }
    }
}

header,
main,
footer {
    flex-shrink: 0;
}

input,
textarea {
    border: 1px solid #ccc;
    padding: 20px;
}

label {
    font-size: 18px;
    display: block;
}

a {
    &:link,
    &:visited {
        color: var(--brand-black);
    }

    &:hover,
    &:focus {
        color: var(--brand-orange);
        outline: none;
    }
}

.app-content {
    max-width: 700px;
    margin: 0 auto;
}

::selection {
    background: var(--brand-orange);
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: inherit;
    margin: inherit;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

// TODO: Fix keyboard focus styles
// ? Experimental feature, must be enabled in chrome v67+
*:focus-visible {
    box-shadow: 0 0 0 2px var(--brand-white), 0 0 0 7px var(--brand-orange) !important;
    border-radius: 3px;
}
