@import './../../globals';

.app__footer {
    padding: 112px 0;
    text-align: center;
    color: var(--brand-white);
    background: var(--brand-blue);
    margin-top: 120px;

    .shx-logo {
        display: block;
    }

    &__policies-list {
        margin-top: 60px;

        &__link:link,
        &__link:visited {
            color: var(--brand-white);
            margin: 30px;
        }
    }

    &__social-icon {
        &:link,
        &:visited {
            display: inline-block;
            margin: 20px;
            width: 30px;
            height: auto;
            color: var(--brand-white);

            transition: transform 0.03s ease-in-out;

            &:hover path,
            &:focus path {
                fill: var(--brand-orange);
            }

            &:active {
                transform: scale(0.85);
            }
        }
    }
}
