.shx-logo {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1;
    color: var(--brand-black);

    &--bold {
        font-weight: 700;
        margin-left: -8px;
    }

    &--inverted {
        color: var(--brand-white);
    }
}
